import store from "../Redux/Store";
import {
  DOMAIN_PURCHASE,
  ROUTES,
  SUBSCRIPTION_TYPE,
  WEB_CONSOLE_ROUTES,
} from "./constants";
import {
  CONSTANTS,
  getLocalState,
  getSessionState,
  removeSessionState,
  setSessionState,
} from "./storage";
import history from "./history";
import {
  goToWabaMarketingPage,
  goToWebConsole,
} from "../Utils/goToWabaMarketingPage";
import { goBackToApp, trackAppEvents } from "./androidEvents";
import {
  goToDashyActiveSubscriptionPage,
  handlePremiumPageRouting,
} from "./premiumPageUtils";
import { ROUTE_CONSTANT } from "../constants/routesConstants";

export function setPurchaseType(type) {
  return setSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE, type);
}

export function getPurchaseType() {
  const retreivedData = getSessionState(
    CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE
  );
  return retreivedData;
}

export function setPrePaymentData(type) {
  return setSessionState(CONSTANTS.SESSION.PRE_PAYMENT_DATA, type);
}

export function getPrePaymentData() {
  const retreivedData = getSessionState(CONSTANTS.SESSION.PRE_PAYMENT_DATA);
  return retreivedData;
}

export function redirectAfterPaymentVerifyFails(type, additionalParams, hist) {
  let route = "";
  switch (type) {
    case 1: {
      const isThemeUpsell = getSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
      const path = isThemeUpsell ? "/theme-upsell" : "/theme-explore";
      route = path;
      break;
    }
    case 2: {
      if (
        getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
        "domain-marketing"
      ) {
        goToWebConsole(WEB_CONSOLE_ROUTES.DOMAIN_SALE);
        break;
      } else if (
        getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
        DOMAIN_PURCHASE
      ) {
        route = ROUTES.DOMAIN_OPEN_SEARCH;
        break;
      }
    }
    case 3:
      route = "/domain-explore";
      break;
    case 4:
      route = "/ads-history";
      break;
    case 5:
      route = "/domain-open-search";
      break;
    case 8:
      handlePremiumPageRouting(
        ROUTE_CONSTANT.THEME_HISTORY,
        goToDashyActiveSubscriptionPage
      );
      break;
    case 14:
      goToWabaMarketingPage(null, additionalParams + "&payment_status=0");
      break;
    case 999:
      route = "/home";
      break;
    case 15: {
      goToWebConsole(`/webview/brand-website-categories${additionalParams}`);
      break;
    }
    case 16:
      goBackToApp();
      break;
  }
  if (route) {
    const completeRoute = route + additionalParams;
    hist ? hist.push(completeRoute) : history.push(completeRoute);
  }
}

export function redirectAfterPremiumSetSuccess(type, additionalParam) {
  const prePaymentData = store.getState().paymentReducer.prePaymentData;
  let route = "";
  let additionalParams = additionalParam;
  switch (type) {
    case 1: {
      const isThemeUpsell = isRedirectForThemeUpsell();
      const path = isThemeUpsell ? "/theme-explore" : "/theme-loader";
      route = path;
      break;
    }
    case 3: {
      route = "/domain-connect-success";
      break;
    }
    case 4:
      route = `/ad-details/${prePaymentData?.theme_id}`;
      break;
    case 2: {
      if (
        getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
        DOMAIN_PURCHASE
      ) {
        additionalParams = `${additionalParams}&isMobile=true`;
        route = `${ROUTES.DOMAIN_PURCHASE_SUCCESS}`;
        break;
      }
    }
    case 5:
    case 8:
      handlePremiumPageRouting(
        ROUTE_CONSTANT.THEME_HISTORY,
        goToDashyActiveSubscriptionPage
      );
      break;
    case 14:
      goToWabaMarketingPage(null, additionalParams + "&payment_status=1");
      break;
    case 999:
      route = "/home";
      break;
    case 15:
      route = "/brand-website-payment-success";
    case 16:
      route = ROUTES.SOCIAL_SHARE_PURCHASE_SUCCESS_PAGE;
      break;
  }
  if (route)
    history.push(route + additionalParams, {
      from: "/payment",
    });
}

function isRedirectForThemeUpsell() {
  const isThemeUpsell = JSON.parse(
    getSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL)
  );
  /** Side effects for the appropriate redirect */
  if (isThemeUpsell) {
    const urlParams = new URLSearchParams(window.location.search);
    const storeId =
      urlParams.get("storeid") ||
      getSessionState("storeid") ||
      getLocalState("storeid");
    trackAppEvents("Theme_Upsell_30M", {
      storeid: storeId,
      type: isThemeUpsell?.purchaseType
        ? SUBSCRIPTION_TYPE.THEME_ONLY
        : SUBSCRIPTION_TYPE.THEME_PLUS,
    });
    setSessionState(
      "themeUpsellSuccessData",
      JSON.stringify({
        fetchUserInfo: true,
        showUpsellSuccessPopup:
          isThemeUpsell?.purchaseType === SUBSCRIPTION_TYPE.THEME_ONLY
            ? true
            : false,
      })
    );
    removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
  }

  return !!isThemeUpsell;
}
