import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError } from "../../Components/Toast";
import apiCall from "../../Services/api";
import { sendDataToApp, trackAppEvents } from "../../Utils/androidEvents";
import {
  removeLoader,
  saveAppConstants,
  saveFeatureLocks,
  saveHelpSectionArticleRoute,
  showLoader,
  toggleDomainPurchasedModal,
  toggleDomainRenewedModal,
  toggleEmailPurchasedModal,
  togglePaymentSuccessModalMethod,
} from "../Actions";
import * as commonTypes from "../ActionTypes";
import {
  CONSTANTS,
  getLocalState,
  getSessionState,
  removeSessionState,
} from "../../Utils/storage";
import { redirectAfterPremiumSetSuccess } from "../../Utils/paymentHelper";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import { goToWebConsole } from "../../Utils/goToWabaMarketingPage";
import {
  DOMAIN_CONNECT,
  ROUTES,
  WEB_CONSOLE_ROUTES,
} from "../../Utils/constants";
import history from "../../Utils/history";

const urlParams = new URLSearchParams(window.location.search);
const app_version = urlParams.get("app_version");

function* recordEvent(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotanalytics/push/pushEventLog`,
      data: actions.data,
    });
    if (response && response.data.status) {
    }
  } catch (err) {}
}

function* isPremiumSet(actions) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const store_id =
      urlParams.get("storeid") ||
      getSessionState("storeid") ||
      getLocalState("storeid");
    const token =
      urlParams.get("token") ||
      getSessionState("token") ||
      getLocalState("token");
    const additionalParams = `?token=${token}&storeid=${store_id}&app_version=${app_version}`;
    const isDomainRenewal = getSessionState(
      CONSTANTS.SESSION.IS_DOMAIN_RENEWAL
    );
    yield put(showLoader());

    // Creating this for later redirection
    const redirectType = actions.data.type;

    /**
     * Assumiing we have only 2 type or
     * subscription 1 for theme and 2
     * for domains
     * */
    if (actions.data.type === 3) actions.data.type = 2;

    let response = {};
    if (actions.data.type === 16) {
      const socialPurchaseObj = getSessionState(
        CONSTANTS.SESSION.SOCIAL_PURCHASE_IMG_OBJ
      );
      const templateId = socialPurchaseObj?.templateId || 0;
      response = yield apiCall({
        method: "GET",
        url: `/dotk/vm1/premium/isSet?type_id=${templateId}&type=${actions?.data?.type}&text=${actions?.data?.text}`,
        // data: actions.data,
      });
    } else {
      response = yield apiCall({
        method: "GET",
        url: `/dotk/vm1/premium/isSet?type_id=${actions?.data?.type_id}&type=${actions?.data?.type}&text=${actions?.data?.text}`,
        // data: actions.data,
      });
    }
    if (response && response?.data?.status) {
      sendDataToApp({
        refreshToken: true,
      });

      if (redirectType === 8) {
        yield put(
          toggleEmailPurchasedModal({
            show: true,
            count: Number(sessionStorage.getItem("emailPurchaseCount")),
          })
        );
      }

      if (redirectType === 2) {
        if (
          getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
          "domain-marketing"
        ) {
          trackAppEvents("Domain_Purchased", {
            storeid: store_id,
          });
          goToWebConsole(WEB_CONSOLE_ROUTES.DOMAIN_COMPLETION);
        } else if (isDomainRenewal) {
          yield put(toggleDomainRenewedModal(true));
          sendDataToApp({
            removeDomainRenewalBanner: true,
          });
          removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
        } else {
          trackAppEvents("Domain_Purchased", {
            storeid: store_id,
          });
          yield put(toggleDomainPurchasedModal(true));
        }
      }
      if (
        redirectType === 3 &&
        getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
          DOMAIN_CONNECT
      ) {
        history.push(`${ROUTES.DOMAIN_CONNECT}${additionalParams}`);
        yield put(togglePaymentSuccessModalMethod());
        return;
      }
      sendDataToApp({
        fetchWorkspaceInfo: true,
      });
      redirectAfterPremiumSetSuccess(redirectType, additionalParams);
    } else {
      ToastNotifyError(`${response?.data?.message}`);
    }
  } catch (error) {
    yield put(removeLoader());
    ToastNotifyError(
      `Unable to verify the payment yet ! please wait for 2-4 hours`
    );
  }
}

function* fetchFeatureLocks() {
  try {
    const response = yield apiCall({
      url: ENDPOINTS.COMMONS.fetchFeatureLocks(),
      parseToJson: true,
    });
    if (response.data.status) {
      yield put(saveFeatureLocks(response.data.data));
    }
  } catch (err) {}
}

function* fetchHelpSectionRoutes() {
  try {
    const response = yield apiCall({
      url: ENDPOINTS.COMMONS.helpSectionRoute,
      parseToJson: true,
    });
    if (response.data.status) {
      yield put(saveHelpSectionArticleRoute(response.data?.routes));
    }
  } catch (err) {}
}
function* domainLandingCallBackRequest(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.DOMAIN.REQUEST_TO_CALLBACK,
      data: actions.data.data,
    });
    if (response && response?.status === 200) {
      actions.data.callback && actions.data.callback(response.status);
    }
  } catch (err) {
    ToastNotifyError("Sending callback request", err);
  }
}

function* fetchAppConstants(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: actions.isDLPCall
        ? ENDPOINTS.COMMONS.GET_DLP_APP_CONSTANTS
        : ENDPOINTS.COMMONS.GET_APP_CONSTANTS,
      parseToJson: true,
    });
    if (response?.data.status) {
      const data = response.data.data;
      yield put(saveAppConstants(data));
      actions.callback && actions.callback(data);
    }
  } catch (err) {
    ToastNotifyError("Facing some internal issue.", err);
  }
}

export default function* root() {
  yield takeEvery(commonTypes.RECORD_ANALYTICS_EVENT, recordEvent);
  yield takeEvery(commonTypes.IS_PREMIUM_SET, isPremiumSet);
  yield takeEvery(commonTypes.FETCH_FEATURE_LOCKS, fetchFeatureLocks);
  yield takeEvery(commonTypes.GET_HELP_SECTION_ROUTES, fetchHelpSectionRoutes);
  yield takeEvery(
    commonTypes.SEND_DOMAIN_CALLBACK_REQUEST,
    domainLandingCallBackRequest
  );
  yield takeEvery(commonTypes.GET_APP_CONSTANTS, fetchAppConstants);
}
