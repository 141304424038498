import { ROUTE_CONSTANT } from "../constants/routesConstants";
import store from "../Redux/Store";
import {
  DEVICE_TYPE,
  PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION,
  WEB_CONSOLE_ROUTES,
} from "./constants";
import { goToWebConsole } from "./goToWabaMarketingPage";
import history from "./history";
import { getMobileOperatingSystem } from "./operatingSystem";

export function getRouteWithParams(Route) {
  const urlParams = new URLSearchParams(window.location.search);
  const storeid = urlParams.get("storeid");
  const token = urlParams.get("token");
  const app_version = urlParams.get("app_version");
  const app_version_code = urlParams.get("app_version_code");
  const version_number = urlParams.get("version_number");
  const channel = urlParams.get("Channel") || urlParams.get("channel");
  const upi_options =
    store.getState().paymentReducer.availableUpiPaymentOptions;
  const additionalParameters = `?token=${token}&storeid=${storeid}&app_version=${app_version}&app_version_code=${app_version_code}&version_number=${version_number}&channel=${channel}&upi_options=${JSON.stringify(
    upi_options
  )}`;
  return `${Route}${additionalParameters}`;
}

export function getWebconsoleUrlWithParams(webconsoleRoute) {
  return getRouteWithParams(webconsoleRoute);
}

export function getPremiumPageUrl() {
  return getWebconsoleUrlWithParams(WEB_CONSOLE_ROUTES.PREMIUM_PAGE);
}

export function getActiveSubscriptionPageUrl() {
  return getWebconsoleUrlWithParams(
    WEB_CONSOLE_ROUTES.ACTIVE_SUBSCRIPTION_STATUS
  );
}

/**
 *  returns true if version1 is less than or equal to version2
 *  Default - returns false
 *  versions should be in string format ( mandatory )
 * @param {*} version1 - string, current version of device
 * @param {*} version2 - string, last supported version for older flow (or feature)
 */
export function isV1LessThanOrEqualToV2(version1 = "", version2 = "") {
  if (
    !version1 ||
    !version2 ||
    typeof version1 !== "string" ||
    typeof version2 !== "string"
  ) {
    return false;
  }

  const group1 = version1.split(".").map((str) => +str);
  const group2 = version2.split(".").map((str) => +str);

  if (group1.some((num) => isNaN(num)) || group2.some((num) => isNaN(num))) {
    return false;
  }

  const maxLength = Math.max(group1.length, group2.length);

  /**
   * if v1 is "1.2"  and v2 is "1.2.3",
   * then make v1 -> "1.2.0"
   */
  const paddedGroup1 = [...group1];
  const paddedGroup2 = [...group2];

  while (paddedGroup1.length < maxLength) {
    paddedGroup1.push(0);
  }

  while (paddedGroup2.length < maxLength) {
    paddedGroup2.push(0);
  }

  for (let i = 0; i < maxLength; i++) {
    if (paddedGroup1[i] < paddedGroup2[i]) {
      return true;
    } else if (paddedGroup1[i] > paddedGroup2[i]) {
      return false;
    }
  }

  // If all parts are equal, version1 is equal to version2
  return true;
}

//function to check backward compatability of premium page.
export function shouldRunOldPremiumPageFlow() {
  const urlParams = new URLSearchParams(window.location.search);
  const app_version = urlParams.get("app_version");

  const currentDeviceType = getMobileOperatingSystem();

  const isOldAndroidAppUser =
    currentDeviceType === DEVICE_TYPE.ANDROID &&
    isV1LessThanOrEqualToV2(
      app_version,
      PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION.ANDROID_APP_VERSION
    );

  const isOldIosAppUser =
    currentDeviceType === DEVICE_TYPE.IOS &&
    isV1LessThanOrEqualToV2(
      app_version,
      PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION.IOS_APP_VERSION
    );

  return isOldAndroidAppUser || isOldIosAppUser;
}

export const goToDashyHomepage = () => goToWebConsole(getPremiumPageUrl());
export const goToDashyActiveSubscriptionPage = () =>
  goToWebConsole(getActiveSubscriptionPageUrl());

export function handlePremiumPageRouting(
  digitalDukaanRouteFromParam,
  goToDashyFromParam
) {
  const { THEME_DISCOVER, THEME_HISTORY } = ROUTE_CONSTANT;
  const storeData = store.getState().storeReducer.data;
  const isOldFlow = shouldRunOldPremiumPageFlow();
  const isPremium = storeData?.store?.premium;

  const digitalDukaanRoute = isPremium ? THEME_HISTORY : THEME_DISCOVER;
  const goToDashy = isPremium
    ? goToDashyActiveSubscriptionPage
    : goToDashyHomepage;

  const finalRouteToRedirect =
    digitalDukaanRouteFromParam || digitalDukaanRoute;
  const finalGoToDashy = goToDashyFromParam || goToDashy;

  isOldFlow
    ? history.push(getRouteWithParams(finalRouteToRedirect))
    : finalGoToDashy();
}
