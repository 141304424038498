import { put, takeEvery } from "redux-saga/effects";
import config from "./../../Config";
import apiCall from "../../Services/api";
import {
  saveDisplayTags,
  saveDisplayThemes,
  saveSearchTags,
  showLoader,
  removeLoader,
  savePrePaymentData,
  saveSubscriptionDetails,
  saveAllStoreThemes,
  saveDomainDetails,
  emptyDisplayThems,
  saveSubscriptionWorkspaceDetails,
} from "../Actions";
import {
  DEACTIVATE_STORE_THEME,
  FETCH_DISPLAY_TAGS,
  FETCH_DISPLAY_THEMES,
  GET_SUBSCRIPTION_DETAILS,
  SEARCH_DISPLAY_TAGS,
  SET_THEME,
  FETCH_ALL_STORE_THEMES,
} from "./../ActionTypes";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import history from "../../Utils/history";
import { sendDataToApp } from "../../Utils/androidEvents";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";
import { DOMAIN } from "../../constants";
import { ENDPOINTS } from "../../Config/apiEndpoints";

function* fetchDisplayTags(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/premium/displayTags/1`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(
        saveDisplayTags({
          businessTypes: response?.data?.data?.business_types,
          colors: response?.data?.data?.colors,
        })
      );
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
  }
}

function* fetchDisplayThemes(actions) {
  yield put(showLoader());
  const url = new URL(window.location.href);
  if (actions.data.page === 1) {
    yield put(emptyDisplayThems());
  }

  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/premium/tagThemes?type=${actions.data.type}&id=${actions.data.id}&page=${actions.data.page}&theme_category=1`,
      parseToJson: true,
    });
    url.searchParams.set("pageNumber", actions.data.page);
    window.history.replaceState(null, null, url); // or pushState

    if (response && response.status) {
      yield put(
        saveDisplayThemes({
          themes: response?.data?.data?.themes,
          isNext: response?.data?.data?.is_next_page,
        })
      );
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
  }
}

function* searchDisplayTags(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/themes/getSearchTags?search_text=${actions.data}`,
    });
    if (response && response.status) {
      yield put(saveSearchTags(response.data.tags));
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
  }
}

function* setTheme(actions) {
  yield put(showLoader());
  sendDataToApp({
    showLoader: true,
  });
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/premium/storeTheme`,
      data: { ...actions.data, theme_category: 1 },
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPurchaseType("theme");
      if (response && response?.data?.data?.is_payable) {
        setPrePaymentData(response?.data?.data?.payment_details);
        yield put(savePrePaymentData(response?.data?.data?.payment_details));
        actions.callback && actions.callback(true);
      } else {
        sendDataToApp({
          refreshToken: true,
        });
        actions.callback && actions.callback(false, response?.data?.data);
      }
    } else {
      ToastNotifyError(response?.data?.message);
    }
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    sendDataToApp({
      refreshToken: true,
    });
    yield put(removeLoader());
  }
}

function* getSubscriptionDetails(action) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.getSubscriptionDetails,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      const premiumData = response.data.data;
      /**
       * Here three new entries has been added
       *  domain --> It will contain the latest domain data
       *  brandWebsiteSubscription --> It will contain brandwebsite subscription data
       *  ecomSubscription --> It will contain ecom subscription data
       */
      yield put(
        saveSubscriptionDetails({
          status: premiumData.theme ? response?.data?.status : false,
          subscription_data: premiumData.theme || {},
          domain: premiumData?.domain || {},
          brandWebsiteSubscription: premiumData?.brand_website || {},
          ecomSubscription: premiumData?.subscription || {},
        })
      );
      let domain = {};
      if (premiumData?.domain) {
        domain = {
          ...premiumData.domain,
          source: DOMAIN.SOURCE.RESELLER,
        };
      } else if (premiumData?.external_domain) {
        domain = {
          ...premiumData.external_domain,
          source: DOMAIN.SOURCE.EXTERNAL,
        };
      }
      yield put(saveDomainDetails(domain));
      yield put(
        saveSubscriptionWorkspaceDetails(premiumData?.workspace || null)
      );
      action.callback && action.callback(true, premiumData);
    } else {
      yield put(
        saveSubscriptionDetails({ status: false, subscription_data: {} })
      );
      action.callback && action.callback(false);
    }
    yield put(removeLoader());
  } catch (error) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch subscription details");
  }
}

function* deactivateStoreTheme(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/themes/deactivateStoreTheme`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
    }
    yield put(removeLoader());
  } catch (error) {
    yield put(removeLoader());
    ToastNotifyError("Unable to deactivate theme !");
  }
}

function* fetchAllStoreThemes(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/themes/getAllStoreThemes?store_id=${actions.data.store_id}&is_demo=${actions.data.is_demo}&page=${actions.data.page}`,
    });
    if (response && response?.data?.status) {
      // actions.callback && actions.callback();
      yield put(
        saveAllStoreThemes({
          isNext: response.data.next,
          themes: response.data.themes || [],
        })
      );
    }
    yield put(removeLoader());
  } catch (error) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch all themes !");
  }
}

export default function* root() {
  yield takeEvery(FETCH_DISPLAY_TAGS, fetchDisplayTags);
  yield takeEvery(SEARCH_DISPLAY_TAGS, searchDisplayTags);
  yield takeEvery(FETCH_DISPLAY_THEMES, fetchDisplayThemes);
  yield takeEvery(SET_THEME, setTheme);
  yield takeEvery(GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails);
  yield takeEvery(DEACTIVATE_STORE_THEME, deactivateStoreTheme);
  yield takeEvery(FETCH_ALL_STORE_THEMES, fetchAllStoreThemes);
}
