import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import * as analyticsTypes from "../ActionTypes";
import { saveOrderInfo, showLoader, removeLoader } from "../Actions";
import history from "../../Utils/history";
import { sendDataToApp } from "../../Utils/androidEvents";

function* createOrder(actions) {
  yield put(showLoader());
  let payload = actions.data;
  payload.items &&
    payload.items.length &&
    payload.items.map((item) => {
      item["item_quantity"] = String(item.item_quantity);
      return item;
    });
  if (actions.data.order_type === 4) {
    delete payload.items;
  }
  // commenting this line such that it can be enabled in future if required
  // payload.communication_mode = "whatsapp";
  try {
    const orderResponse = yield apiCall({
      method: "POST",
      data: payload,
      url: `/dotk/order/createMerchantOrder`,
    });
    if (orderResponse && orderResponse.data.status) {
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ sendSelfBill: true })
        );
      yield put(removeLoader());
      yield put(saveOrderInfo(orderResponse.data.order_id));

      if (orderResponse?.data?.wa_template_text) {
        sendDataToApp({
          shareTextOnWhatsApp: true,
          data: orderResponse.data?.wa_template_text,
          mobileNumber: payload?.phone,
        });
      }
      history.push("/receipt");
    }
  } catch (err) {
    yield put(removeLoader());
  }
}

export default function* root() {
  yield takeEvery(analyticsTypes.CREATE_ORDER, createOrder);
}
